<template lang="pug">
.detail-w
  GeminiScrollbar.my-scroll-w(style="height: 100%")
    .content-d
      .title-d {{ advisoryDetail.title }}
      .date-d {{ advisoryDetail.createTime }}
      .desc-d(v-html="advisoryDetail.content")
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      advisoryDetail: (state) => state.advisoryCenter.advisoryDetail, //详情数据
    }),
  },
};
</script>

<style lang="less" scoped>
.detail-w {
  width: 68%;
  margin: 0 auto;
  background: rgba(28, 42, 117, 0.4);
  color: #fff;
  height: 82vh;
  .content-d {
    .df;
    .dfc;
    padding: 20px 80px;
    & > div {
      width: 100%;
    }
  }
  .title-d {
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    padding: 20px 0;
  }
  .date-d {
    font-size: 14px;
    color: #5a86d3;
    line-height: 21px;
    text-align: center;
    padding-bottom: 20px;
  }
  .desc-d {
    flex: 1;
    font-size: 16px;
    color: #5a86d3;
    line-height: 24px;
  }
}

/deep/ .desc-d img {
  max-width: 100%;
}
</style>
